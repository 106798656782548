import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import AdSense from 'react-adsense';

import {
  Container,
  Content,
  TextDate,
  Card,
  Text,
  CardInfo,
  CardConverter,
  ContentConverter,
  TextConverter,
  TextInput,
  TextInfo,
  TitleInfo,
  Title,
} from './styles';

import api from '../../services/api';

import Header from '../../components/Header';

export default function Euro() {
  const [data, setData] = useState({});
  const [value, setValue] = useState(1.0);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const response = await api.get('/json/all');

      setData(response.data);
    } catch (err) {
      toast.error('Erro ao tentar buscar as informações.');
    }
  }

  return (
    <>
      <Header />
      <Container>
        <AdSense.Google client="ca-pub-2832909420983428" />
        <Content>
          <TextDate>
            Atualizado:{' '}
            {moment(data?.USD?.create_date).format('DD/MM/YYYY HH:mm:ss')}
          </TextDate>

          <Card>
            <Title>EURO</Title>
            <Text>
              {data?.EUR?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.EUR?.bid)
                : '0.00'}
            </Text>
          </Card>

          <CardConverter>
            <Title>Converta de EURO (€) para real (R$):</Title>

            <ContentConverter>
              <TextConverter dolar>€:</TextConverter>
              <TextInput
                value={value}
                maxLength={7}
                onChange={(e) => setValue(e.target.value.replace(',', '.'))}
              />

              <TextConverter>=&nbsp;</TextConverter>
              <TextConverter dolar>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(value * Number(data?.EUR?.bid ? data?.EUR?.bid : 0))}
              </TextConverter>
            </ContentConverter>
          </CardConverter>

          <CardInfo>
            <TitleInfo>Euro :</TitleInfo>
            <TextInfo>
              Euro (símbolo: €; código: EUR) é a moeda oficial da zona Euro, a
              qual é constituída por 19 dos 27 estados-membro da União Europeia:
              Alemanha, Áustria, Bélgica, Chipre, Eslováquia, Eslovénia,
              Espanha, Estónia, Finlândia, França, Grécia, Irlanda, Itália,
              Letónia, Lituânia, Luxemburgo, Malta, Países Baixos e Portugal.
              <br />
              A moeda é também usada de forma oficial pelas instituições da
              União Europeia e por quatro outros países europeus e, de forma
              unilateral, por outros dois. Em 2018, a moeda era usada
              diariamente por cerca de 343 milhões de europeus.
              <br />
              A moeda é também usada oficialmente em diversos territórios
              ultramarinos da UE.
              <br />
              A moeda é ainda usada por mais 240 milhões de pessoas em todo o
              mundo, das quais 190 milhões em África, que usam moedas de câmbio
              fixo em relação ao euro.
              <br />
              O euro é a segunda maior moeda de reserva e a segunda moeda mais
              transaccionada no mundo a seguir ao dólar dos Estados Unidos.
              <br />
              Com mais de 1,2 trilhão de euros em circulação em 2018, o euro tem
              o maior valor combinado de notas e moedas em circulação no mundo,
              tendo ultrapassado o dólar norte-americano.
              <br />
              Com base em estimativas do Fundo Monetário Internacional do PIB e
              da paridade do poder de compra, a zona euro é a segunda maior
              economia do mundo.
              <br />
              O nome "euro" foi oficialmente adotado em 16 de dezembro de 1995.
              <br />
              O euro foi introduzido nos mercados financeiros mundiais enquanto
              unidade de conta a 1 de janeiro de 1999, em substituição da antiga
              Unidade Monetária Europeia (ECU), a um câmbio de 1:1 (1,1743 USD).
              <br />
              As moedas e notas físicas de euro entraram em circulação a 1 de
              janeiro de 2002, tornando-a a moeda de uso corrente entre os
              membros originais.
              <br />
              Embora nos primeiros dois anos a cotação do euro tenha descido
              para 0,8252 USD (26 de outubro de 2000), a partir do fim de 2002
              começou a ser transacionada a valores superiores ao dólar,
              atingindo um máximo de 1,6038 USD em 18 de julho de 2008.
              <br />
              A partir do fim de 2009, a crise da dívida pública da Zona Euro
              levou à criação do Fundo Europeu de Estabilização Financeira e à
              adoção de várias reformas de estabilização monetária.
              <br />
            </TextInfo>
            <TitleInfo>História do Euro</TitleInfo>
            <TextInfo>
              A ideia do estabelecimento da moeda única na CEE nasceu já na
              década de 70.
              <br />
              Teve como principais defensores os Economistas Fred Arditti, Neil
              Dowling, Wim Duisenberg, Robert Mundell, Tommaso Padoa-Schioppa e
              Robert Tollison.
              <br />
              No entanto, só pelo Tratado de Maastricht, de 1992 esta ideia
              passou da teoria para o Direito.
              <br />
              Este tratado foi celebrado pelos doze países que à data faziam
              parte da Comunidade Económica Europeia.
              <br />
              O Reino Unido e a Dinamarca optaram neste tratado por ficar de
              fora da moeda única.
              <br />
              Na teoria os países que aderissem posteriormente à União teriam
              que aderir à moeda única.
              <br />
              A Suécia aderiu à União em 1995 mas negociou entrar numa fase
              posterior.
              <br />
              Os critérios para adesão à nova moeda única foram estabelecidos
              pelo Pacto de Estabilidade e Crescimento de 1997.
              <br />
              O primeiro nome para o sistema de conversão entre as moedas que se
              uniriam foi o ECU (European Currency Unit em Inglês).
              <br />
              O nome de Euro é atribuído ao Belga German Pirloit que assim o
              sugeriu a Jacques Santer em 1995.
              <br />
              O valor da nova moeda foi ancorado ao do ECU por resolução do
              Conselho da União Europeia de 31 de dezembro de 1998.
              <br />
              Esta entrou em vigor a 1 de janeiro de 1999 em forma não material
              (transferências, cheques, etc.) e a 1 de janeiro de 2002 em notas
              e moedas.
              <br />
            </TextInfo>
          </CardInfo>

          <CardInfo>
            <TextInfo>
              <a
                href="https://pt.wikipedia.org/wiki/Wikip%C3%A9dia:P%C3%A1gina_principal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fonte das informações: Wikipédia
              </a>
            </TextInfo>
          </CardInfo>
        </Content>
      </Container>
    </>
  );
}
