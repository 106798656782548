import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${colors.white};
  elevation: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
`;

export const Content = styled.div`
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: ${colors.primary};
  margin-left: 20px;
`;

export const Button = styled.div`
  border: 1px solid ${colors.primary};
  padding: 10px 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
`;

export const ButtonText = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.primary};
`;
