import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';

import Home from './pages/Home';
import Euro from './pages/Euro';
import Others from './pages/Others';

function Routes() {
  return (
    <BrowserRouter>
      <Route component={Home} path="/" exact />
      <Route component={Euro} path="/euro" />
      <Route component={Others} path="/others" />
    </BrowserRouter>
  );
}

export default Routes;
