import React from 'react';

import {
  Container,
  Content,
  Left,
  Right,
  Title,
  Button,
  ButtonText,
} from './styles';

export default function Header() {
  return (
    <Container>
      <Content>
        <Left>
          <Title>Dólar Hoje</Title>
        </Left>
        <Right>
          <a href="/" rel="noopener noreferrer">
            <Button>
              <ButtonText>Dólar Hoje</ButtonText>
            </Button>
          </a>
          <a href="/euro" rel="noopener noreferrer">
            <Button>
              <ButtonText>Euro Hoje</ButtonText>
            </Button>
          </a>
          <a href="/others" rel="noopener noreferrer">
            <Button>
              <ButtonText>Outras Moedas</ButtonText>
            </Button>
          </a>
        </Right>
      </Content>
    </Container>
  );
}
