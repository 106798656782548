import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import AdSense from 'react-adsense';

import {
  Container,
  Content,
  TextDate,
  Card,
  Text,
  CardInfo,
  CardConverter,
  ContentConverter,
  TextConverter,
  TextInput,
  TextInfo,
  TitleInfo,
  Title,
} from './styles';

import api from '../../services/api';

import Header from '../../components/Header';

export default function Others() {
  const [data, setData] = useState({});
  const [value, setValue] = useState(1.0);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const response = await api.get('/json/all');

      setData(response.data);
    } catch (err) {
      toast.error('Erro ao tentar buscar as informações.');
    }
  }

  return (
    <>
      <Header />
      <Container>
        <AdSense.Google client="ca-pub-2832909420983428" />
        <Content>
          <TextDate>
            Atualizado:{' '}
            {moment(data?.USD?.create_date).format('DD/MM/YYYY HH:mm:ss')}
          </TextDate>

          <Card>
            <Title>PESO ARGENTINO</Title>
            <Text>
              {data?.ARS?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.ARS?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>BITCOIN</Title>
            <Text>
              {data?.BTC?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.BTC?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>LITECOIN</Title>
            <Text>
              {data?.LTC?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.LTC?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>IENE JANPONES</Title>
            <Text>
              {data?.JPY?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.JPY?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>FRANCO SUÍÇO</Title>
            <Text>
              {data?.CHF?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.CHF?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>DÓLAR AUSTRALIANO</Title>
            <Text>
              {data?.AUD?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.AUD?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>YUAN CHINÊS</Title>
            <Text>
              {data?.CNY?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.CNY?.bid)
                : '0.00'}
            </Text>
          </Card>
        </Content>
      </Container>
    </>
  );
}
