import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  background-color: ${colors.grey};

  @media (min-width: 1000px) {
    width: 1000px;
  }
`;

export const Content = styled.div`
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  elevation: 2px;
  padding: 14px 20px;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
`;

export const Text = styled.span`
  font-size: 50px;
  color: ${colors.primary};
  font-weight: bold;
`;

export const TextConverter = styled.span`
  font-size: 50px;
  color: ${(props) => (props.dolar ? colors.primary : colors.black)};
  font-weight: bold;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  elevation: 2px;
  padding: 40px 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

export const CardConverter = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  elevation: 2px;
  padding: 40px 40px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

export const ContentConverter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const TextInput = styled.input`
  width: 250px;
  font-weight: bold;
  font-size: 40px;
  padding: 5px 20px;
  border-radius: 5px;
  border-width: 1px;
  text-align: center;
  margin: 20px 20px;
`;

export const TitleInfo = styled.span`
  font-size: 25px;
  color: ${colors.primary};
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const TextInfo = styled.span`
  font-size: 20px;
  color: ${colors.black};
`;

export const TextDate = styled.span`
  font-size: 16px;
  color: ${colors.grey};
  text-align: right;
  padding: 10px 20px;
  font-weight: bold;
`;

export const Title = styled.span`
  font-size: 30px;
  font-weight: bold;
`;
