import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import AdSense from 'react-adsense';

import {
  Container,
  Content,
  TextDate,
  Card,
  Text,
  CardInfo,
  CardConverter,
  ContentConverter,
  TextConverter,
  TextInput,
  TextInfo,
  TitleInfo,
  Title,
} from './styles';

import api from '../../services/api';

import Header from '../../components/Header';

export default function Home() {
  const [data, setData] = useState({});
  const [value, setValue] = useState(1.0);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const response = await api.get('/json/all');

      setData(response.data);
    } catch (err) {
      toast.error('Erro ao tentar buscar as informações.');
    }
  }

  return (
    <>
      <Header />
      <Container>
        <AdSense.Google client="ca-pub-2832909420983428" />
        <Content>
          <TextDate>
            Atualizado:{' '}
            {moment(data?.USD?.create_date).format('DD/MM/YYYY HH:mm:ss')}
          </TextDate>

          <Card>
            <Title>DÓLAR COMERCIAL</Title>
            <Text>
              {data?.USD?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.USD?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>DÓLAR TURISMO</Title>
            <Text>
              {data?.USDT?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.USDT?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>DÓLAR CANADENSE</Title>
            <Text>
              {data?.CAD?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.CAD?.bid)
                : '0.00'}
            </Text>
          </Card>

          {/* <Card>
            <Title>EURO</Title>
            <Text>
              {data?.EUR?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.EUR?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>PESO ARGENTINO</Title>
            <Text>
              {data?.ARS?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.ARS?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>BITCOIN</Title>
            <Text>
              {data?.BTC?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.BTC?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>LITECOIN</Title>
            <Text>
              {data?.LTC?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.LTC?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>IENE JANPONES</Title>
            <Text>
              {data?.JPY?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.JPY?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>FRANCO SUÍÇO</Title>
            <Text>
              {data?.CHF?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.CHF?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>DÓLAR AUSTRALIANO</Title>
            <Text>
              {data?.AUD?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.AUD?.bid)
                : '0.00'}
            </Text>
          </Card>

          <Card>
            <Title>YUAN CHINÊS</Title>
            <Text>
              {data?.CNY?.bid
                ? Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data?.CNY?.bid)
                : '0.00'}
            </Text>
          </Card> */}

          <CardConverter>
            <Title>Converta de dólar (U$$) para real (R$):</Title>

            <ContentConverter>
              <TextConverter dolar>U$$:</TextConverter>
              <TextInput
                value={value}
                maxLength={7}
                onChange={(e) => setValue(e.target.value.replace(',', '.'))}
              />

              <TextConverter>=&nbsp;</TextConverter>
              <TextConverter dolar>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(value * Number(data?.USD?.bid ? data?.USD?.bid : 0))}
              </TextConverter>
            </ContentConverter>
          </CardConverter>

          <CardInfo>
            <TitleInfo>O Dólar:</TitleInfo>
            <TextInfo>
              O dólar (símbolo: $) é o nome comum de moedas oficiais de 9
              países: Austrália, Canadá, Estados Unidos, Hong Kong, Jamaica,
              Namíbia, Nova Zelândia, Singapura e Taiwan.
              <br />
              <br />O dólar dos Estados Unidos além de ser a moeda dos Estados
              Unidos é a moeda oficial do El Salvador, Equador, Ilhas Marshall,
              Micronésia, Países Baixos Caribenhos, Palau, Timor-Leste e o
              Panamá que usa as notas bancárias.
              <br />
              <br />É dividido em 100 centavos.[carece de fontes] História Em 15
              de janeiro de 1520, o Reino da Boêmia começou a cunhagem de moedas
              de prata extraídas localmente em Joachimsthal (atual Jáchymov) e
              marcado no inverso com o leão tcheco.
              <br />
              <br />
              As moedas eram chamadas de joachimsthaler, que foi encurtado em
              uso comum para táler.
              <br />
              <br /> Os pesos espanhóis era também conhecidos como dólar
              espanhol.
              <br />
              <br /> No século XVIII, o dólar de Lion foi substituído pelo dólar
              espanhol, as famosas peças de oito, que era distribuído pelas
              colonizações espanholas e pelas Filipinas.
              <br />
              <br />
              Países que usaram o dólar Espanha — A Espanha já usou o dólar de
              Lion e o dólar espanhol, que foi substituído pela peseta e depois
              pelo euro. Malásia — A moeda é o Ringgit malaio, que também foi
              chamada de dólar malaio. Rodésia — O dólar rodesiano substituiu a
              libra rodesiana, que quando mudou o nome para Zimbabwe foi
              substituído pelo dólar do Zimbabwe. Zimbabwe — O dólar do Zimbabwe
              foi abolido, agora usa o dólar estadunidense, o rand, o euro, a
              libra esterlina, o pula, o renminbi, a rupia indiana e o iene.
            </TextInfo>
            <TitleInfo>Dólar Comercial</TitleInfo>
            <TextInfo>
              Dólar comercial é a cotação do Dólar americano (US$) com paridade
              na moeda brasileira (R$), usado como parâmetro de pagamento nas
              transações com importações/exportações de produtos via CACEX/Banco
              do Brasil.
            </TextInfo>
            <TitleInfo>Dólar Sinal ($)</TitleInfo>
            <TextInfo>
              O sinal de dólar ou símbolo de dólar (do inglês dollar), também
              conhecido pelo sinal do peso ($), é um símbolo usado para indicar
              a moeda de vários países. <br />
              <br />É também o marcador da moeda de outros países, como por
              exemplo, Argentina, Chile, Colômbia, Equador, Guiana, México,
              Suriname e Uruguai. <br />
              <br />
              Origem O uso mais antigo do sinal aparece em correspondência
              comercial no Reino Unido, Estados Unidos da América, Canadá e
              México e outros países da América espanhola para denominar o peso
              usado pelos espanhóis nas colónias do Novo Mundo, também conhecido
              como dólar espanhol ou moeda de oito (reais).
            </TextInfo>
          </CardInfo>

          <CardInfo>
            <TextInfo>
              <a
                href="https://pt.wikipedia.org/wiki/Wikip%C3%A9dia:P%C3%A1gina_principal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fonte das informações: Wikipédia
              </a>
            </TextInfo>
          </CardInfo>
        </Content>
      </Container>
    </>
  );
}
